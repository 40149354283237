@import "src/helpers/constants";

.desc_filter_trigger {
  font-family: Roboto-Medium, sans-serif;
  font-size: 16px;
  padding: 10px 16px;
  margin-left: 6px;
  border: solid 1px $light-border-color;
  color: $text-color;
  cursor: pointer;
  transition: background-color .2s;

  &:hover {
    background-color: $light-background-color;
  }
}
