$main-color: #D4246D;
$accent-color: #00E9D8;
$text-color: #444444;
$label-color: #AAAAAA;
$dark-color: #3F274D;

$main-color-hover: #ec5494;
$main-color-darker: #b40c52;
$accent-color-light: #eefff9;
$accent-color-text: #006b63;

$delete-light-color: #feecf0;
$delete-color: #f6c5d0;
$delete-text: #cc0f35;

$error-color: #f14668;
$light-border-color: #dadada;
$light-background-color: #f3f3f3;
$cancel-background-color: #ffe08a;
$cancel-background-light-color: #fae7bd;
$cancel-background-dark-color: #ffce46;

$main-paddings: 30px;

$green_color: #30AD04;
$yellow_color: #E2CE19;
$red_color: #E21919;
