@import "src/helpers/constants";

.checklists_wrapper {
  .header_buttons_row {
    display: flex;

    .header_button {
      margin-right: 10px;
    }
  }

  .refresh_button {
    width: 42px;
    height: 42px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
  }
  .refresh {
    width: 18px;
    height: 18px;

    &::selection {
      opacity: 0;
    }
  }

  .checklists_tree {
    width: fit-content;
  }

  .checklists_ul {
    padding-left: 30px;
    list-style: none;
  }

  .checklists_li {
  }

  .checklists_item {
    display: flex;
    align-items: center;
    padding: 5px 0;
    position: relative;
  }

  .checklists_expand_arrow {
    position: absolute;
    left: -30px;
    top: 5px;
    transform: rotate(-90deg);
    cursor: pointer;
    transition: all .2s;

    &:hover {
      opacity: 0.4;
    }
    &:active {
      opacity: 0.9;
    }

    &.closed {
      transform: rotate(-180deg);
    }

    .checklists_expand_arrow_img {
      width: 22px;
      object-fit: contain;
    }
  }

  .checklists_icon {
    width: 22px;
    object-fit: contain;
    margin-right: 8px;
  }

  .checklists_folder_text {
    font-family: Roboto-Medium, sans-serif;
    color: $text-color;
    font-size: 16px;
    padding: 4px 0;
    cursor: default;

    transition: color .2s;
  }

  .checklists_template_text {
    font-family: Roboto-Light, sans-serif;
    color: $text-color;
    font-size: 16px;
    padding: 10px 0;
    cursor: default;

    transition: color .2s;
  }

  .checklists_folder_buttons {
    display: none;
    align-items: flex-end;
    margin-left: 20px;

    .checklists_folder_button {
      margin-left: 10px;
      cursor: pointer;
      transition: opacity .2s;

      &:hover {
        opacity: 0.6;
      }

      & img {
        width: 16px;
        object-fit: contain;
        margin-bottom: -4px;
      }
    }

    &.disabled {
      .checklists_folder_button {
        cursor: default;

        &:hover {
          opacity: 1;
        }

        & img {
          filter: grayscale(100%);
          opacity: 0.5;
        }
      }
    }
  }

  .checklists_folder_buttons_visible:hover, .checklists_template_to_edit:hover {

    .checklists_folder_buttons {
      display: flex;
    }

    .checklists_folder_text {
      color: $main-color
    }
  }

  .checklists_template_to_start:hover {

    & .checklists_template_text {
      color: $main-color
    }

    & .checklists_template_button {
      display: block;
    }
  }

  .checklists_template_button {
    padding: 6px 10px;
    margin-left: 20px;
    display: none;
  }
}

.checklist_modal_wrapper {
  padding: 20px;
  width: 500px;

  & .checklist_modal_text {
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
    margin-bottom: 20px;
  }

  & .checklist_modal_button {
    width: fit-content;
    margin-left: auto;
  }
}
