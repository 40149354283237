@import './src/helpers/constants';

.language_selector_trigger {
  background-color: $main-color;
  margin-bottom: 10px;
  position: relative;
  transition: background-color .2s;

  &:hover {
    background-color: $main-color_hover;
  }

  &:hover .showing{
    display: block;
  }

  .row {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    cursor: pointer;
    transition: background-color .2s;

    &:hover {
      background-color: $main-color;
    }
  }

  .img {
    width: 30px;
    margin-right: 10px;
  }

  .text {
    color: white;
  }

  .showing {
    display: none;
    position: absolute;
    background-color: white;
    width: 100%;
    left: 0;
    top: 100%;
    box-shadow: 0 10px 15px 2px $label-color;

    &:hover .text{
      color: white;
    }

    .text {
      color: $text-color;
    }
  }
}
