@import "src/helpers/constants";

.edit_drawing_wrapper {

  .edit_drawing_item_wrapper {
    padding: 40px 0;
    border-bottom: 1px solid $light-border-color;

    &.last {
      border-bottom-width: 0;
    }

    .drawing_item_title {
      color: $text-color;
      font-family: Roboto-Regular, sans-serif;
      font-size: 18px;
      margin-bottom: 20px;
    }

    .drawing_item_row {
      display: flex;
      align-items: flex-start;
    }

    .drawing_item_left {
      width: 35%;
      margin-right: 30px;
    }

    .drawing_thumbnail_block {
      width: 100%;
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .drawing_thumbnail {
      max-width: 100%;
      object-fit: contain;
    }

    .edit_drawing_delete_button {
      display: inline-block;
      margin-top: 30px;
    }

    .edit_drawing_save_button {
      display: inline-block;
    }

    .drawing_item_right {
      width: 60%;
    }

    .edit_drawing_select {
      width: 350px;
      margin-bottom: 24px;
    }

    .edit_drawing_input {
      margin-bottom: 24px;
    }

    .edit_drawing_checkbox {
      margin-bottom: 24px;
    }

    .date_block {
      margin-bottom: 24px;

       & p {
         color: $text-color;
         font-family: Roboto-Medium, sans-serif;
         font-size: 16px;
         margin-bottom: 12px;
       }

       & span {
         color: $text-color;
         font-family: Roboto-Light, sans-serif;
         font-size: 16px;
       }
    }

  }

  .no_drawing {
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
  }

}
