.iframe_div {
  width: 100%;
  height: 100%;

  .iframe {
    width: 100%;
    height: 100%;

  }
}
