@import "src/helpers/constants";

.page_row {
  margin: 40px 0;
  padding: 0 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .page_item {
    margin: 6px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto-Light, sans-serif;
    color: $text-color;
    border: solid 1px $light-border-color;
    transition: background-color .2s;

    &:hover {
      background-color: $light-background-color;
    }

    &:active {
      background-color: $label-color;
    }

    &.page_item_active {
      background-color: $main-color;
      color: white;
    }
  }
}
