@import "src/helpers/constants";

.events_wrapper {

  .header_row, .item_row {
    display: flex;

    .item {
      padding: 10px 14px;
      border-bottom: solid 1px $light-border-color;
      font-size: 16px;
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      text-align: center;

      &.first {
        width: 20%;
      }
      &.second {
        width: 20%;
      }
      &.third {
        width: 20%;
      }
      &.fourth {
        width: 15%;
      }
      &.fifth {
        width: 15%;
      }
      &.sixth {
        width: 20%;
      }
    }
  }

  .item_row {
    transition: background-color .2s;

    &.clickable {
      cursor: pointer;
    }

    .item {
      font-family: Roboto-Light, sans-serif;
      padding: 16px 14px;
    }
    &:hover {
      background-color: $light-background-color;
    }
  }
}
