@import "src/helpers/constants";

.single_checkup_wrapper {
  .checkup_header_right {
    display: flex;
    border: 1px solid $label-color;
    align-items: center;
    padding: 8px 12px;

    & img {
      width: 26px;
      object-fit: contain;
      margin-right: 10px;
    }

    & p {
      color: $label-color;
      font-family: Roboto-Regular, sans-serif;
    }
  }

  .no_checkup {
    font-family: Roboto-Regular, sans-serif;
    color: $text-color;
    font-size: 16px;
  }

  .top_part {
    display: flex;

    .selections_part {
      width: 65%;
      height: 300px;

      &.no_height {
        height: unset;
      }

      .selection_part_item {
        height: 54px;
        display: flex;
        align-items: center;

        &.at_start {
          align-items: flex-start;
        }

        &.at_start_with_margin {
          align-items: flex-start;
          margin-top: 20px;
        }

        .selection_title {
          width: 30%;
          color: $text-color;
          font-family: Roboto-Regular, sans-serif;

          &.text_at_start {
            margin-top: 16px;
          }

          &.text_at_start_small {
            margin-top: 8px;
          }
        }

        .selection_value {
          font-family: Roboto-Regular, sans-serif;
          color: $text-color;
          font-size: 16px;
          width: 70%;
          margin-bottom: 0;

          &.selection_value_error {
            color: $error-color;
          }
        }
      }
    }

    .tools_part {
      width: 40%;
      margin-left: 5%;

      .tools_title {
        font-family: Roboto-Regular, sans-serif;
        color: $text-color;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .tools_list {
        list-style: disc;
        max-height: 200px;
        overflow-y: auto;
        padding: 0 20px;
        width: fit-content;
        margin-bottom: 20px;

        .tools_item {
          font-family: Roboto-Light, sans-serif;
          color: $text-color;
          font-size: 16px;
          margin-bottom: 10px;
        }
      }

      .tools_text {
        font-family: Roboto-Light, sans-serif;
        color: $text-color;
        font-size: 14px;
        margin-bottom: 10px;
        padding-left: 20px;
      }
    }
  }

  .checklist_part {
    margin-top: 40px;

    .checklist_title {
      color: $text-color;
      font-family: Roboto-Regular, sans-serif;
      font-size: 34px;
      margin-bottom: 24px;
    }

    .marks_wrapper {
      margin-bottom: 30px;

      .marks_title {
        color: $text-color;
        font-family: Roboto-Regular, sans-serif;
        font-size: 20px;
        margin-bottom: 14px;
      }

      .marks_subtitle_row {
        display: flex;
        margin-bottom: 24px;

        & img {
          width: 40px;
          object-fit: contain;
          margin-right: 20px;
        }

        & p {
          color: $text-color;
          font-family: Roboto-Light, sans-serif;
          font-size: 16px;
          margin-top: 8px;
        }
      }

      .marks_list {
        .mark_item {
          display: flex;
          align-items: center;
          padding-left: 20px;
          margin-bottom: 20px;
        }

        .mark_button {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $main-color;
          margin-right: 20px;
          cursor: pointer;
          transition: opacity .2s;

          &:hover {
            opacity: 0.7;
          }

          &:active {
            opacity: 0.9;
          }

          & img {
            width: 22px;
            object-fit: contain;
          }

          &.accepted {
            background-color: $green-color;
            cursor: default;
            &:hover {
              opacity: 1;
            }
            &:active {
              opacity: 1;
            }
          }

          &.denied {
            background-color: $red_color;
            cursor: default;
            &:hover {
              opacity: 1;
            }
            &:active {
              opacity: 1;
            }
          }

          &.disabled {
            background-color: $label-color;
          }
        }

        .mark_info_block {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .mark_info_title {
            color: $text-color;
            font-family: Roboto-Regular, sans-serif;
            font-size: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 720px;
          }

          .mark_info_text {
            color: $text-color;
            font-family: Roboto-Light, sans-serif;
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 720px;
          }
        }
      }
    }
  }

  .prescription_part {
    max-width: 900px;
    border: 1px solid $main-color;
    position: relative;
    margin-top: 80px;
    margin-bottom: 40px;

    &.expanded {
      border-width: 2px;
      padding: 30px;
    }

    .checklist_no_object {
      color: $error-color;
      font-family: Roboto-Regular, sans-serif;
      font-size: 14px;
      position: absolute;
      top: -18px;
      left: 18px;
    }

    & .floating_part {
      position: absolute;
      border: 2px solid $main-color;
      background-color: white;
      padding: 6px 60px;
      top: -19px;
      right: 64px;
      cursor: pointer;
      transition: all .2s;

      &:hover {
        background-color: #f1f1f1;
      }
      &:active {
        background-color: #e5e5e5;

      }

      &.non_pressable {
        cursor: default;
        &:hover {
          background-color: white;
        }
        &:active {
          background-color: white;
        }
      }

      & p {
        color: $main-color;
        font-family: Roboto-Regular, sans-serif;
        font-size: 16px;
      }
    }

    .revealed_text {
      font-family: Roboto-Medium, sans-serif;
      margin-bottom: 10px;
      color: $text-color;
    }

    .go_to_prescription_button {
      margin-top: 10px;
      display: inline-block;
    }
  }

  .single_checkup_buttons_row {
    display: flex;
    margin-top: 20px;
  }

  .finish_checkup {
    margin-right: 12px;
  }

  .submit_checkup {
    margin-right: 12px;
  }

  .note_input {
    resize: vertical;
    margin-top: 10px;
    max-height: 90px;
  }

  .line {
    width: 150%;
    border-bottom: 1px $light-border-color solid;
    margin: 60px 0 40px;
  }

  .checkup_request_buttons_row {
    display: flex;
    margin-top: 60px;
  }
}
