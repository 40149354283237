@import "src/helpers/constants";

.single_prescription_wrapper {
  .single_prescription_item {
    margin-bottom: 10px;
    width: 500px;
  }
  .single_prescription_item_big {
    margin-bottom: 20px;
  }

  .default_size {
    width: 580px;
  }

  .priority_row {
    display: flex;
    justify-content: space-between;

    .priority_item {
      display: flex;
      align-items: center;

      .priority_label {
        display: flex;
        align-items: center;
        color: $text-color;
        cursor: pointer;

        & span {
          font-family: Roboto-Light, sans-serif;
        }
      }
    }
  }

  .circle {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    margin-right: 4px;
    margin-left: 4px;
    display: inline-block;

    &.green {
      background-color: $green-color;
    }
    &.yellow {
      background-color: $yellow-color;
    }
    &.red {
      background-color: $red-color;
    }
  }

  .buttons_row {
    display: flex;
    margin-top: 20px;
    justify-content: flex-start;

    .repeat_button {
      margin-right: -2px;
    }
  }

  .line {
    height: 1px;
    background-color: $light-border-color;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .need_agreement {
    margin-bottom: 15px;
  }

  .input_main {
    padding: 13px 10px;
  }

  .input_date {
    padding: 12px 10px;
  }

  .input_item {
    margin-right: 10px;

    &.secondary {
      width: 226px;
      height: 44px;

      &.small {
        width: 100px;
        margin-left: 10px;
      }
    }
  }

  .action_image {
    width: 44.5px;
    height: 44.5px;
    padding: 3px;
    border: 1px solid $main-color;
    border-radius: 4px;
    margin-left: 10px;
  }

  .close_button {
    height: 44px;
  }

  .prescription_item_title {
    font-family: Roboto-Medium, sans-serif;
    color: $text-color;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .prescription_margin_right {
    margin-right: 6px;
  }
  .margin_right {
    margin-right: 12px;
  }
  .margin_left {
    margin-left: 12px;
  }
  .prescription_simple_text {
    font-family: Roboto-Light, sans-serif;
    color: $text-color;
    font-size: 16px;
  }

  .view_info_row {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    .view_info_text {
      font-family: Roboto-Light, sans-serif;
      color: $text-color;
      font-size: 16px;

      &.margin_right {
        margin-right: 6px;
      }

      &.overdue {
        color: $red-color;
        font-family: Roboto-Regular, sans-serif;
        font-size: 20px;
      }
    }
  }

  .info_text {
    font-family: Roboto-LightItalic, sans-serif;
    color: $label-color;
    font-size: 12px;
    margin-top: 4px;
  }

  .margin_top {
    margin-top: 10px;
  }


  .signing_wrapper {
    margin-top: 20px;

    .uploading_button {
      display: inline-block;
      margin-right: 10px;
    }

  }
}

.no_prescription {
  color: $text-color;
  font-family: Roboto-Regular, sans-serif;
  font-size: 30px;
}

.display_inline_block {
  display: inline-block;
}

.comment {
  font-family: Roboto-LightItalic, sans-serif;
  color: $label-color;
  border-left: 10px $main-color solid;
  margin-left: 10px;
  padding: 4px 0 4px 10px;
  line-height: 22px;
  margin-bottom: 20px;
}
