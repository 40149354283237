@import "src/helpers/constants";

.projects_wrapper {

  .project_item {
    padding: 15px 15px;
    border-bottom: 1px solid $light-border-color;
    display: flex;
    justify-content: space-between;
    transition: background-color .2s;

    &:hover {
      background-color: $light-background-color;
    }

    .project_left {
      display: flex;
    }

    .project_img {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      color: $text-color;

    }

    .project_title {
      font-family: Roboto-Medium, sans-serif;
      font-size: 20px;
      margin-bottom: 4px;
      color: $text-color;

    }

    .project_desc {
      font-family: Roboto-LightItalic, sans-serif;
      font-size: 16px;
      color: $text-color;
    }

    .project_address {
      margin-bottom: 10px;
      font-family: Roboto-Light, sans-serif;
      font-size: 16px;
      color: $text-color;
    }

    .project_info {
      font-family: Roboto-Light, sans-serif;
      font-size: 16px;
      margin-top: 2px;
      color: $text-color;
    }

    .project_row {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;

      .group_item {
        margin-left: 6px;
        margin-bottom: 6px;
        font-family: Roboto-Light, sans-serif;
        font-size: 13px;
        color: $text-color;
        background-color: $cancel-background-color;
        padding: 2px 6px;
        border-radius: 4px;
      }
    }

    .added_to_sm {
      margin-left: 6px;
      margin-top: 6px;
      display: inline-block;
      font-family: Roboto-Light, sans-serif;
      font-size: 13px;
      color: white;
      background-color: #3e8ed0;
      padding: 3px 6px;
      border-radius: 4px;
    }
  }
}
