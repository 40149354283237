@import "src/helpers/constants";

.single_company_wrapper {
  .single_company_input {
    margin-bottom: 20px;
  }

  .buttons_row {
    display: flex;
  }
}

.no_object {
  font-family: Roboto-Regular, sans-serif;
  font-size: 30px;
}
