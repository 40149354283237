@import './src/helpers/constants';

.input_div {
  width: 100%;
  margin-bottom: 10px;

  .input {
    width: 100%;
    border: solid 1px $main-color;
    outline: none;
    padding: 10px;
    display: block;
    border-radius: 4px;
    color: $text-color;
    transition: background-color, border-color .2s;

    &.error_input {
      border-color: $error-color;
    }

    &:focus {
      border-color: $accent-color;
    }

    &.date_input {
      width: 200px;
    }
  }

  .label {
    font-family: Roboto-Medium, sans-serif;
    margin-bottom: 10px;
    display: inline-block;
    color: $text-color;
  }

  .error {
    font-family: Roboto-Light, sans-serif;
    color: $error-color;
    font-size: 14px;
    margin-top: 6px;
  }
  .info {
    font-family: Roboto-LightItalic, sans-serif;
    color: $text-color;
    font-size: 16px;
    margin-top: 6px;
  }
}
