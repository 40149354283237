@import "src/helpers/constants";

.viol_item {

  .default_size {
    width: 580px;
  }

  .viol_input_main {
    padding: 13px 10px;
  }

  .viol_input_item {
    margin-right: 10px;

    &.hide {
      display: none;
    }

  }

  .viol_close_button {
    height: 44px;
    width: 44px;
    padding: 10px 12px;
  }

  .viol_action_image {
    width: 44.5px;
    height: 44.5px;
    padding: 3px;
    border: 1px solid $main-color;
    border-radius: 4px;
    margin-left: 10px;
  }

  .viol_drawing_button {
    height: 44px;
    width: 44px;
    margin-right: 10px;
    padding: 10px 12px;
  }

  .camera_button {
    height: 44px;
    width: 44px;
    margin-right: 10px;
    padding: 10px 12px;

    .camera_button_image {
      margin: 0 auto;
    }

    &.with_photo {
      background-color: white;
      box-sizing: border-box;
      border: 1px solid $main-color;
      border-radius: 4px;
      padding: 0;
      overflow: hidden;
      transition: opacity .2s;


      &:hover {
        opacity: .8;

        .camera_button_image {
          width: 36px;
          height: 36px;
        }
      }

      .camera_button_image {
        height: 42px;
        width: 42px;
        transition: height .2s, width .2s;
      }
    }
  }
}

@keyframes blurringAnimation {
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(8px);
  }
}


.drawing_modal_wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.57);
  backdrop-filter: blur(8px);
  animation-name: blurringAnimation;
  animation-duration: .7s;

  .drawing_modal_container {
    max-width: 1000px;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    padding-top: 40px;
  }

  .buttons_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .drawing_modal_button {
    height: 44px;
    width: 44px;
    padding: 10px 12px;
  }

  .list_wrapper {
    height: 70%;

    .object_title {
      color: white;
      font-family: Roboto-Medium, sans-serif;
      margin-bottom: 4px;
      font-size: 30px;
    }

    .object_address {
      color: white;
      font-family: Roboto-Medium, sans-serif;
      font-size: 16px;
    }

    .list_scroll_view {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      height: 100%;
      overflow-y: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .drawing_item {
        width: 23%;
        height: fit-content;

        margin-right: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        cursor: pointer;
        transition: all .2s;

        &:hover {
          transform: scale(0.97);
        }

        &:active {
          transform: scale(0.93);
        }

        .drawing_preview {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          margin-bottom: 10px;
          min-height: 230px;
        }

        .drawing_name {
          color: white;
          font-family: Roboto-Medium, sans-serif;
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }

  .item_wrapper {
    height: calc(100% - 100px);

    .drawing_title {
      color: white;
      font-family: Roboto-Medium, sans-serif;
      margin-bottom: 8px;
      font-size: 30px;
    }

    .drawing_info_row {
      display: flex;
      align-items: center;

      .drawing_info_img {
        width: 17px;
      }

      .drawing_info_text {
        color: white;
        font-family: Roboto-Medium, sans-serif;
        font-size: 14px;
        margin: 0 20px 0 6px;
      }
    }

    .single_drawing_wrapper {
      width: 100%;
      height: 70%;
      margin-top: 30px;

      .zoom_buttons_row {
        position: absolute;
        display: flex;
        z-index: 100;
        width: 102px;
        justify-content: space-between;
        padding: 6px;

        .size_button {
          .size_button_text {
            font-size: 20px;

            &::selection {
              background-color: rgba(0, 0, 0, 0);
            }
          }
        }
      }

      .drawing_clickable_wrapper {
        width: fit-content;
        height: 100%;
        max-width: 100%;
        overflow: scroll;
      }
      .drawing_clickable {
        width: 100%;
        height: 100%;
        position: relative;
      }

      .single_drawing_photo {
        width: 100%;
        object-fit: contain;
      }
    }

    .drawing_bottom_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;

      .drawing_info_div {
        border: 2px $label-color solid;
        border-radius: 12px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        max-width: 35%;

        & img {
          width: 38px;
          height: 38px;
          object-fit: contain;
          margin-right: 6px;
        }

        & span {
          color: $label-color;
          font-family: Roboto-Regular, sans-serif;
          font-size: 16px;
        }
      }

      .slider_div {
        width: 60%;
        display: flex;
        justify-content: space-between;

        .slider_container {
          width: 80%;
        }

        .slider {
          -webkit-appearance: none;
          width: 100%;
          height: 6px;
          background: white;
          outline: none;
          opacity: 1;
          -webkit-transition: .2s;
          transition: opacity .2s;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 15px;
            height: 40px;
            background: $main-color;
            cursor: pointer;

            border: 1px solid white;
          }

          &::-moz-range-thumb {
            width: 15px;
            height: 40px;
            background: $main-color;
            cursor: pointer;

            border: 1px solid white;

          }
        }

        .angles_row {
          width: 104.6%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          margin-left: -2.4%;

          .angle_item {
            display: flex;
            flex-direction: column;
            align-items: center;

            .angle_stick {
              width: 1px;
              height: 10px;
              background-color: white;
              margin-bottom: 2px;

              &.with_margin {
                margin-right: 6px;
              }
            }

            .angle_value {
              font-family: Roboto-Light, sans-serif;
              color: white;
              font-size: 14px;
            }
          }
        }

        .ok_button {
          height: 35px;
          padding: 10px 5%;
        }
      }
    }

  }
}
