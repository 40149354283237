@import "src/helpers/constants";

.single_letterhead_wrapper {
  .single_letterhead_select {
    margin-bottom: 20px;
    width: 300px;
  }

  .single_letterhead_input {
    margin-bottom: 20px;
  }

  .buttons_row {
    display: flex;
  }

  .logo_cell {
    width: 44.5px;
    height: 44.5px;
    padding: 3px;
    border: 1px solid $main-color;
    border-radius: 4px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
}

.no_object {
  font-family: Roboto-Regular, sans-serif;
  font-size: 30px;
}
