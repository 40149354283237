@import "src/helpers/constants";

.checkbox_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: fit-content;
  transition: all .2s;

  &:active {
    transform: scale(0.95);
  }

  &.with_right_label {
    width: 100%;

    &:active {
      transform: scale(0.99);
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;

    &:active {
      transform: scale(1);
    }
  }

  .left_part {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .box {
    width: 15px;
    height: 15px;
    margin-right: 6px;
    border: 1px solid $main-color;
    display: flex;
    justify-content: center;
    align-items: center;

    .tick {
      width: 18px;
      height: 18px;
    }

    &.disabled {
      border: 1px solid $text-color;
    }
  }

  .label {
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
    font-size: 16px;
    display: inline-block;
    max-width: 90%;
  }

}
