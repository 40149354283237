@import "src/helpers/constants";

.sort_type_filter {
  position: relative;

  .trigger {
    font-family: Roboto-Medium, sans-serif;
    font-size: 16px;
    padding: 10px 16px;
    border: solid 1px $light-border-color;
    background-color: white;
    color: $text-color;
    cursor: pointer;
    transition: background-color .2s;

    &:hover {
      background-color: $light-background-color;
    }

    &:focus + .showing {
      visibility: visible;
      opacity: 1;
      top: calc(100% - 2px)
    }
  }

  .showing {
    visibility: hidden;
    opacity: 0;
    top: 110%;
    background-color: white;
    position: absolute;
    padding: 6px 0;
    z-index: 10;
    width: 180px;
    border: solid 1px $light-border-color;
    right: 0;
    transition: visibility 0s, opacity 0.1s, top 0.2s;

    .item {
      padding: 10px 16px;
      color: $text-color;
      cursor: pointer;
      transition: background-color .2s;

      &.active {
        color: $accent-color;
      }

      &:hover {
        background-color: $light-background-color;
      }

      &:active {
        background-color: $label-color;
      }
    }
  }
}
