@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto'), url(../public/fonts/Roboto/Roboto-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto'), url(../public/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto'), url(../public/fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto'), url(../public/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto'), url(../public/fonts/Roboto/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: local('Roboto'), url(../public/fonts/Roboto/Roboto-BlackItalic.ttf) format('truetype');
}


@font-face {
  font-family: 'Roboto-BoldItalic';
  src: local('Roboto'), url(../public/fonts/Roboto/Roboto-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: local('Roboto'), url(../public/fonts/Roboto/Roboto-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-RegularItalic';
  src: local('Roboto'), url(../public/fonts/Roboto/Roboto-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: local('Roboto'), url(../public/fonts/Roboto/Roboto-LightItalic.ttf) format('truetype');
}


* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: Roboto-Regular, sans-serif;
}

input {
  font-size: 14px;
}

::placeholder {
  color: #AAAAAA;
}

.row {
  display: flex;
}
