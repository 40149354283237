@import "src/helpers/constants";

.profile_wrapper {

  .profile_simple_text {
    font-family: Roboto-Regular, sans-serif;
    color: $text-color;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .language_select {
    width: 200px;
  }

  .password_label {
    font-family: Roboto-Medium, sans-serif;
    color: $text-color;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .password_button {
    width: 200px;
    display: flex;
    justify-content: center;
  }

  .my_allocations_wrapper {
    margin-top: 40px;
  }

  .schedule_part {
    margin-top: 40px;
  }

  .my_allocations_header {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .my_allocations_title {
    font-family: Roboto-Regular, sans-serif;
    color: $text-color;
    font-size: 20px;
    margin-right: 14px;
  }

  .schedule_title {
    font-family: Roboto-Regular, sans-serif;
    color: $text-color;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .my_allocations_item {
    border-left: 6px solid $main-color;
    padding-left: 10px;
    margin-left: 10px;

    .my_alloc_item_title {
      font-family: Roboto-Medium, sans-serif;
      color: $text-color;
      font-size: 18px;
      margin-bottom: 8px;
    }

    .my_alloc_item_info {
      font-family: Roboto-Regular, sans-serif;
      color: $text-color;
      font-size: 16px;
      margin-bottom: 4px;
    }
  }

  .schedule_item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .schedule_label {
      font-family: Roboto-Light, sans-serif;
      color: $text-color;
      font-size: 16px;
      width: 140px;
    }

    .schedule_line {
      font-family: Roboto-Light, sans-serif;
      color: $text-color;
      font-size: 12px;
      margin: 0 8px;
    }

    .schedule_select {
      width: 120px;
      margin-bottom: 0;
    }
  }

  .buttons_row {
    display: flex;
    align-items: center;
    margin-top: 80px;
  }
}

.change_password_modal_wrapper {
  width: 550px;

  & .change_password_modal_input {
    height: 130px;
  }

  & .change_password_modal_buttons_row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
