@import "src/helpers/constants";

.single_drawing_wrapper {

  .single_drawing_top_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 700px;
  }

  .single_drawing_left {
    width: 65%;
    height: 100%;

    .drawing_scroll_view {
      width: 100%;
      height: 100%;
      overflow: auto;
      position: relative;

      .drawing_img {
        object-fit: contain;
        margin-bottom: -4px;
        margin-right: -4px;
      }
    }
  }

  .buttons_row {
    display: flex;
    align-items: center;
    margin-top: 18px;

    .size_button {
      margin-right: 10px;

      .size_button_text {
        font-size: 20px;

        &::selection {
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }

  .single_drawing_right {
    width: 30%;
    height: 100%;

    .right_title {
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      font-size: 18px;
    }

    .is_open_checkbox {
      margin: 18px 0 18px 6px;
    }

    .viols_scroll_view {
      width: 100%;
      overflow-y: scroll;
      height: calc(100% - 76px);
    }
  }

  .drawing_info_row {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .drawing_info_item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .drawing_info_img {
        width: 20px;
        height: 20px;
        object-fit: contain;

        &.small {
          width: 16px;
          margin: 0 2px;
        }
      }

      .drawing_info_title {
        color: $text-color;
        font-family: Roboto-Medium, sans-serif;
        margin-left: 8px;
        min-width: 200px;
      }

      .drawing_info_text {
        color: $text-color;
        font-family: Roboto-Regular, sans-serif;
        margin-left: 8px;
      }
    }

    .single_drawing_pravka {
      margin-left: 140px;
    }
  }

  .single_drawing_bottom {
    margin-top: 40px;

    .single_drawing_bottom_title {
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      font-size: 22px;
    }

    .single_drawing_bottom_scroll_view {
      width: 100%;
      padding: 26px 0;
      overflow-x: scroll;
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .no_drawing {
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
  }
}

.drawing_viol_item {
  width: 100%;
  height: 100px;
  padding: 8px;
  background-color: $main-color;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;

  .left_part {
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .row {
      display: flex;
      align-items: center;
      height: 30%;
      margin-bottom: 4px;

      .number {
        width: 30px;
        padding: 4px 0;
        text-align: center;
        color: $main-color;
        font-family: Roboto-Medium, sans-serif;
        background-color: #fff;
        border-radius: 15px;
        font-size: 18px;
      }

      .prescription_title {
        color: #fff;
        font-family: Roboto-Regular, sans-serif;
        margin-left: 8px;
        font-size: 16px;
        text-decoration: underline;
        cursor: pointer;
        transition: opacity .2s;

        &:active {
          opacity: 0.65;
        }
      }
    }

    .drawing_viol_text {
      color: #fff;
      font-family: Roboto-Regular, sans-serif;
      max-height: 66%;
      overflow-y: auto;
    }
  }

  .image_part {
    width: 30%;
    height: 100%;
    display: flex;

    .drawing_viol_img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
