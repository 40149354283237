@import "src/helpers/constants";

.cancel_button {
  background-color: $cancel-background-color;

  &.with_margin {
    margin-left: 12px;
  }

  &_text {
    color: $text-color !important;
    font-family: Roboto-Light, sans-serif !important;
  }

  &:hover {
    background-color: $cancel-background-light-color !important;
  }

  &:active {
    background-color: $cancel-background-dark-color !important;
  }
}
