@import "src/helpers/constants";

.single_alloc_wrapper {
  .single_alloc_input {
    margin-bottom: 20px;
  }

  .buttons_row {
    display: flex;
  }

  .selected_alloc {
    margin-bottom: 20px;

    .selected_alloc_title {
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .selected_alloc_small_title {
      font-family: Roboto-Medium, sans-serif;
      font-size: 18px;
      color: $text-color;
    }

    .selected_alloc_info {
      color: $text-color;
      font-family: Roboto-Light, sans-serif;
      font-size: 16px;
      margin-bottom: 4px;
    }

    .selected_alloc_desc {
      color: $text-color;
      font-family: Roboto-LightItalic, sans-serif;
      font-size: 16px;
      margin-bottom: 4px;
    }
  }
}

.no_alloc {
  color: $text-color;
  font-family: Roboto-Regular, sans-serif;
  font-size: 30px;
}
