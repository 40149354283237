@import "src/helpers/constants";

.pdf_iframe_component {
  margin-top: 20px;

  .pdf_buttons_row {
    display: flex;
    margin: 20px 0 30px;
  }

  .pdf_button {
    margin-right: 10px;

    &.icon {
      padding: 10px 12px;
    }
  }

  .no_letterhead_text {
    font-family: Roboto-Light, sans-serif;
    color: $text-color;
    font-size: 15px;
  }

  .no_letterhead_wrapper {
    width: 840px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pdf_iframe_wrapper {
    width: fit-content;
    padding: 15px 15px 15px 15px;
    border: 1px solid $light-border-color;
    border-radius: 10px;

    .pdf_iframe {
      width: 840px;
      height: 600px;
      border: 0;
    }

    &.non_visible {
      display: none;
    }
  }

  .approved_with {
    height: 40px;
    border: 1px solid $light-border-color;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    font-family: Roboto-Light, sans-serif;
    color: $text-color;
  }
}
