@import "src/helpers/constants";

.drawing_marker_wrapper {
  .drawing_marker_text {
    font-family: Roboto-Medium, sans-serif;
    color: #fff;
    text-align: center;
    position: absolute;
    z-index: 2;
    width: 100%;
    cursor: default;
  }
}
