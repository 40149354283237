@import "src/helpers/constants";

.letterheads_wrapper {

  .letterhead_item {
    padding: 15px 15px;
    border-bottom: 1px solid $light-border-color;
    display: flex;
    justify-content: space-between;
    transition: background-color .2s;

    &:hover {
      background-color: $light-background-color;
    }

    .letterhead_left {
      display: flex;
    }

    .letterhead_img {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      color: $text-color;

    }

    .letterhead_title {
      font-family: Roboto-Medium, sans-serif;
      font-size: 20px;
      margin-bottom: 10px;
      color: $text-color;

    }

    .letterhead_desc {
      font-family: Roboto-LightItalic, sans-serif;
      font-size: 16px;
      color: $text-color;
    }

    .letterhead_address {
      margin-bottom: 10px;
      font-family: Roboto-Light, sans-serif;
      font-size: 16px;
      color: $text-color;
    }

    .letterhead_info {
      font-family: Roboto-Light, sans-serif;
      font-size: 16px;
      margin-top: 2px;
      color: $text-color;

    }
  }
}
