@import "src/helpers/constants";

.single_act_wrapper {
  .single_act_item {
    margin-bottom: 10px;
  }
  .single_act_item_big {
    margin-bottom: 20px;
  }

  .default_size {
    width: 580px;
  }

  .prescription_item_error {
    font-family: Roboto-Light, sans-serif;
    color: $error-color;
    font-size: 14px;
    margin-top: 6px;
  }

  .prescription_item {
    margin-bottom: 10px;
  }

  .big_input {
    min-height: 150px;
    max-width: 986px;
    overflow: auto;
    resize: vertical;
  }

  .priority_row {
    display: flex;
    justify-content: space-between;

    .priority_item {
      display: flex;
      align-items: center;

      .priority_label {
        display: flex;
        align-items: center;
        color: $text-color;
        cursor: pointer;

        & span {
          font-family: Roboto-Light, sans-serif;
        }
      }
    }
  }

  .circle {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    margin-right: 4px;
    margin-left: 4px;
    display: inline-block;

    &.green {
      background-color: $green-color;
    }
    &.yellow {
      background-color: $yellow-color;
    }
    &.red {
      background-color: $red-color;
    }
  }

  .buttons_row {
    display: flex;
    margin-top: 20px;

    .repeat_button {
      margin-right: -2px;
    }
  }

  .line {
    height: 1px;
    background-color: $light-border-color;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .need_agreement {
    margin-bottom: 15px;
  }

  .input_main {
    padding: 13px 10px;
  }

  .input_date {
    padding: 12px 10px;
  }

  .input_item {
    margin-right: 10px;

    &.secondary {
      width: 226px;
      height: 44px;

      &.small {
        width: 100px;
        margin-left: 10px;
      }
    }
  }

  .action_image {
    width: 44.5px;
    height: 44.5px;
    padding: 3px;
    border: 1px solid $main-color;
    border-radius: 4px;
    margin-left: 10px;
  }

  .close_button {
    height: 44px;
  }

  .act_item_title {
    font-family: Roboto-Medium, sans-serif;
    color: $text-color;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .act_margin_right {
    margin-right: 6px;
  }
  .margin_right {
    margin-right: 12px;
  }
  .margin_left {
    margin-left: 12px;
  }
  .act_simple_text {
    font-family: Roboto-Light, sans-serif;
    color: $text-color;
    font-size: 16px;
  }

  .view_info_row {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    .view_info_text {
      font-family: Roboto-Light, sans-serif;
      color: $text-color;
      font-size: 16px;

      &.margin_right {
        margin-right: 6px;
      }

      &.overdue {
        color: $red-color;
        font-family: Roboto-Regular, sans-serif;
        font-size: 20px;
      }
    }
  }

  .info_text {
    font-family: Roboto-LightItalic, sans-serif;
    color: $label-color;
    font-size: 12px;
    margin-top: 4px;
  }

  .margin_top {
    margin-top: 10px;
  }


  .signing_wrapper {
    margin-top: 20px;

    .uploading_button {
      display: inline-block;
    }

  }
}

.sign_modal_wrapper {
  width: 500px;

  &.share {
    width: 600px;
  }

  .modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_title {
    font-family: Roboto-Regular, sans-serif;
    color: $text-color;
    font-size: 16px;
  }

  .modal_small_title {
    font-family: Roboto-Regular, sans-serif;
    color: $text-color;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .modal_close_button {
    cursor: pointer;
    padding: 4px;
    width: 40px;
    height: 40px;
    transition: background-color .2s;

    &:hover {
      background-color: $light-background-color;
    }

    &:active {
      background-color: $light-border-color;
    }
  }

  .modal_input {
    margin-bottom: 20px;
  }

  .modal_close {
    width: 32px;
    height: 32px;
  }

  .modal_row {
    display: flex;
    align-items: center;
  }

  .margin_bottom {
    margin-bottom: 20px;
  }

  .modal_input_info {
    font-family: Roboto-Light, sans-serif;
    font-size: 14px;
  }

  .modal_checkbox {
    margin-bottom: 10px;
  }

  .modal_checkbox_label {
    font-family: Roboto-Light, sans-serif !important;
  }

  .modal_nav_button {
    background-color: white;
    margin-bottom: 20px;

    &.active {
      background-color: $main-color;
    }
  }
  .modal_nav_text {
    color: $text-color;

    &.active {
      color: white;
    }
  }

  .modal_choose_button {
    display: inline-block;
    margin-bottom: 30px;
  }

  .warning_img {
    width: 40px;
    height: 40px;
    margin-left: 20px;
    margin-top: 9px;
  }

  .warning_text {
    font-family: Roboto-Light, sans-serif;
    color: $text-color;
    opacity: 0.5;
  }
  .modal_info {
    margin-bottom: 20px;
    color: $text-color;
    font-family: Roboto-Light, sans-serif;
    font-size: 14px;
  }

  .modal_main {
    margin-bottom: 20px;
    max-height: 300px;
    overflow: auto;
  }

  .file_item {
    margin-bottom: 10px;
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
    font-size: 14px;
    width: fit-content;
    cursor: pointer;
    transition: color .2s;

    &:hover {
      color: $main-color
    }

    .file_close {
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }
  }
 }


.no_act {
  color: $text-color;
  font-family: Roboto-Regular, sans-serif;
  font-size: 30px;
}

.display_inline_block {
  display: inline-block;
}

.comment {
  font-family: Roboto-LightItalic, sans-serif;
  color: $label-color;
  border-left: 10px $main-color solid;
  margin-left: 10px;
  padding: 4px 0 4px 10px;
  line-height: 22px;
  margin-bottom: 20px;
}
