@import "src/helpers/constants";

.single_object_wrapper {
  .single_object_input {
    margin-bottom: 20px;
  }

  .buttons_row {
    display: flex;
  }

  .selected_company {
    margin-bottom: 20px;

    .selected_company_title {
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .selected_company_info {
      color: $text-color;
      font-family: Roboto-Light, sans-serif;
      font-size: 16px;
      margin-bottom: 4px;
    }

    .selected_company_desc {
      color: $text-color;
      font-family: Roboto-LightItalic, sans-serif;
      font-size: 16px;
      margin-bottom: 4px;
    }
  }

  .appointment_register_row {
    margin: 20px 0;
    display: flex;

    & p {
      margin-right: 10px;
      font-family: Roboto-Medium, sans-serif;
      display: inline-block;
      color: #444444;
    }
  }
}

.no_object {
  color: $text-color;
  font-family: Roboto-Regular, sans-serif;
  font-size: 30px;
}
