@import "src/helpers/constants";
.single_checklist_wrapper {

  .no_category {
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
  }

  .single_checklist_container {
    padding-left: 20px;
    max-width: 700px;

    .id_row {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .bold_text {
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      margin-right: 10px;
    }
    .simple_text {
      color: $text-color;
      font-family: Roboto-Light, sans-serif;
      margin-right: 10px;
    }

    .category_input {
      margin-bottom: 20px;
    }

    .category_select {
      width: 100%;
    }
  }

  .buttons_row {
    display: flex;
    margin-top: 50px;
  }
}
