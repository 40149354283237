@import "src/helpers/constants";

.wrapper {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  display: flex;

  .container {
    width: calc(100% - 300px);
    min-width: 1000px;
    padding: $main-paddings;
    height: 100vh;
    overflow: auto;
  }
}

@media screen and (max-width: 1280px){
  .wrapper {
    .container {
      width: 100%;
    }
  }
}

