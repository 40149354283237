@import "src/helpers/constants";

.appointment_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .main {
    min-width: 300px;
    max-width: 500px;
    width: calc(100% - 40px);
    overflow: hidden;
    height: 100vh;

    & .main_logo {
      height: 56px;
      object-fit: contain;
    }

    .scroll_view {
      overflow: scroll;
      height: calc(100% - 56px);
      padding-bottom: 40px;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }


  .page_title {
    font-family: Roboto-Medium, sans-serif;
    color: $text-color;
    font-size: 26px;
    margin-bottom: 30px;
  }

  .simple_block {
    margin-bottom: 20px;

    & .small_title {
      margin-bottom: 8px;
    }
  }

  .small_title {
    font-family: Roboto-Medium, sans-serif;
    color: $text-color;
    font-size: 18px;
  }

  .simple_text {
    font-family: Roboto-Regular, sans-serif;
    color: $text-color;
    font-size: 12px;

    &.ellipsis {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .info_block {
    padding: 18px;
    background-color: $main-color;
    display: flex;
    align-items: flex-start;

    .info_block_img {
      width: 12%;
      object-fit: contain;
      margin-right: 4%;
    }

    .info_block_title {
      font-family: Roboto-Regular, sans-serif;
      color: white;
      font-size: 18px;
      margin: 6px 0 10px;
    }

    .info_block_text {
      font-family: Roboto-Regular, sans-serif;
      color: white;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .schedule_block {
    margin-top: 40px;

    .engineer_block {
      margin-top: 20px;

      .slots_row {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        .slots_item {
          width: 22%;
          margin-right: 4%;
          display: flex;
          justify-content: center;
          height: 30px;
          margin-bottom: 20px;

          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .time_row {
    display: flex;
    align-items: center;
  }

  .status_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -8px;
  }

  .time_button {
    height: 26px;
    margin-left: 40px;

    .time_button_text {
      font-size: 14px;
    }
  }

  .margin_top {
    margin-top: 20px;
  }

  .margin_top_big {
    margin-top: 40px;
  }

  .big_input {
    min-height: 50px;
    max-height: 90px;
    overflow: auto;
    resize: vertical;
  }

  .button_text {
    font-size: 14px;
    text-align: center;
    width: 100%;
    font-family: Roboto-Light, sans-serif;
  }

  .bottom_button {
    height: 30px;
    margin-top: 20px;

    &.bottom_button_small {
      width: 48%;
    }

    &.no_margin {
      margin-top: 0;
    }
  }

  .buttons_row_appointment {
    display: flex;
  }

  .save_div {
    padding: 10px 18px;
    border: 2px solid $label-color;
    display: flex;
    align-items: center;
    margin-top: 20px;

    .save_div_img {
      width: 12%;
      max-height: 30px;
      object-fit: contain;
      margin-right: 4%;
    }

    .save_div_text {
      font-family: Roboto-Regular, sans-serif;
      color: $label-color;
      font-size: 14px;

      & span {
        font-family: Roboto-MediumItalic, sans-serif;
        margin-right: 4px;
      }
    }

    .save_div_link {
      font-family: Roboto-Medium, sans-serif;
      color: $label-color;
      font-size: 14px;
      text-decoration: underline;
      margin-top: 6px;
    }
  }

  .my_appointment_item {
    border: 2px solid $main-color;
    padding: 14px 8px 8px;
    margin-bottom: 30px;
    height: 130px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: opacity .2s;

    &:hover {
      opacity: 0.86;
    }

    &:active {
      opacity: 0.7;
    }

    .difference {
      position: absolute;
      height: 26px;
      width: 160px;
      border: 1px solid $main-color;
      background-color: white;
      top: -13px;
      right: 13px;
      display: flex;
      align-items: center;
      justify-content: center;

      .difference_text {
        font-family: Roboto-Regular, sans-serif;
        color: $main-color;
        font-size: 14px;
      }
    }

    .bottom_row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left_part {
        display: flex;
        align-items: center;

        .tick_icon {
          width: 20px;
          object-fit: contain;
        }

        .status_text {
          font-family: Roboto-Regular, sans-serif;
          color: $main-color;
          font-size: 16px;
          margin-left: 4px;
        }
      }
    }
  }

  .error_text {
    font-family: Roboto-Regular, sans-serif;
    color: $label-color;
    font-size: 12px;
    text-decoration: underline;
    text-align: center;
    margin-top: 20px;
  }

  .error_text_red {
    font-family: Roboto-Regular, sans-serif;
    color: $error-color;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }
}


.appointment_delete_text {
  font-family: Roboto-Regular, sans-serif;
  color: $text-color;
  font-size: 14px;
  width: 80vw;
  max-width: 400px;
  text-align: center;
}


.appointment_delete_info {
  font-family: Roboto-Medium, sans-serif;
  color: $text-color;
  font-size: 16px;
  margin: 14px 0;
  text-align: center;
}

.buttons_row_appointment {
  display: flex;
  justify-content: space-between;
}

.buttons_row_appointment_modal {
  display: flex;
  justify-content: space-evenly;
}
