@import "src/helpers/constants";

.single_user_wrapper {
  .single_user_input {
    margin-bottom: 20px;
  }

  .buttons_row {
    display: flex;
  }

  .selected_role {
    margin-bottom: 20px;

    .selected_role_title {
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      font-size: 20px;
    }
  }

  .other_error {
    color: $error-color;
    font-family: Roboto-Light, sans-serif;
    font-size: 16px;
    margin-top: 10px;
  }

  .user_role {
    max-width: 400px;
  }
}

.no_object {
  color: $text-color;
  font-family: Roboto-Regular, sans-serif;
  font-size: 30px;
}
