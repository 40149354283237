@import "src/helpers/constants";

.single_project_wrapper {
  .single_project_input {
    margin-bottom: 20px;
  }

  .buttons_row {
    display: flex;
  }

  .selected_letterhead {
    margin-bottom: 20px;

    .selected_letterhead_title {
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .selected_letterhead_info {
      color: $text-color;
      font-family: Roboto-Light, sans-serif;
      font-size: 16px;
      margin-bottom: 4px;
    }
  }
}

.no_object {
  color: $text-color;
  font-family: Roboto-Regular, sans-serif;
  font-size: 30px;
}
