@import "src/helpers/constants";

.acts_wrapper {

  .header_row, .item_row {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px $light-border-color;

    .item {
      padding: 10px 14px;
      font-size: 16px;
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      text-align: center;
      display: flex;
      justify-content: center;

      &.first {
        width: 10%;
      }
      &.second {
        width: 10%;
      }
      &.third {
        width: 10%;
      }
      &.fourth {
        width: 17%;
        max-height: 100px;
        overflow-y: auto;
      }
      &.fifth {
        width: 15%;
      }
      &.sixth {
        width: 15%;
      }
      &.seventh {
        width: 15%;
      }
      &.eights {
        width: 20%;

        &.overdue {
          color: $red-color;
        }

        &.canceled {
          color: $label-color;
        }
      }
      &.ninth {
        width: 6%;
      }

      &.pressable span:hover {
        text-decoration: underline;
      }

      &.pressable

      .circle {
        height: 16px;
        width: 16px;
        border-radius: 18px;
        display: inline-block;

        &.red {
          background-color: $red-color;
        }
        &.yellow {
          background-color: $yellow-color;
        }
        &.green {
          background-color: $green-color;
        }
      }

      .small_number {
        font-size: 14px;
        color: $label-color;
        display: flex;
        align-items: center;

        .arrow_right {
          width: 12px;
          object-fit: contain;
          margin: 0 2px;
        }
      }
    }

    .cloud_button {
      width: 34px;
      height: 34px;
      padding: 0;
      justify-content: center;
    }
  }

  .item_row {
    transition: background-color .2s;

    &.clickable {
      cursor: pointer;
    }

    .item {
      font-family: Roboto-Light, sans-serif;
      padding: 16px 14px;
    }
    &:hover {
      background-color: $light-background-color;
    }
  }
}
