@import './src/helpers/constants';

.button_div {
  padding: 10px 15px;
  cursor: pointer;
  background-color: $main-color;
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color .2s;

  &.disabled_button {
    background-color: $label-color;
    cursor: default;
  }

  &:hover:not(.disabled_button) {
    background-color: $main-color-hover;
  }

  &:active:not(.disabled_button) {
    background-color: $main-color-darker;
  }

  .button_icon {
    width: 20px;
    height: 20px;

    &::selection {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .button_text {
    color: white;
    font-family: 'Roboto-Regular', sans-serif;
  }

  .disabled {
    opacity: 0;
  }
}
