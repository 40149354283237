@import 'src/helpers/constants';

.select_div {
  width: 100%;
  margin-bottom: 10px;

  &.select_div_with_height {
    position: relative;
    min-height: 44px;
  }

  .simple_text {
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
  }


  .select_trigger {
    background-color: $main-color;
    text-align: start;
    padding: 10px 15px;
    outline: none;
    border: 0;
    color: white;
    font-family: Roboto-Light, sans-serif;
    font-size: 15px;
    caret-color: transparent;
    cursor: pointer;
    transition: background-color .2s;

    &:hover {
      background-color: $main-color-hover;
    }

    &:focus + .select_list {
      visibility: visible;
      opacity: 1;
      top: 38px;

      &.at_only_dropdown {
        top: 67px;

        &.without_label {
          top: 38px
        }
      }
    }

    &.select_trigger_with_height {
      width: 100%;
    }
  }

  .not_found {
    font-family: Roboto-LightItalic, sans-serif;
    margin-bottom: 10px;
    color: $text-color;
  }

  .select_list {
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    top: 42px;
    box-shadow: 0 4px 15px #e0e0e0;
    max-height: 400px;
    position: absolute;
    z-index: 10;
    min-width: 50%;
    transition: visibility 0s, opacity 0.1s, top 0.2s;

    .select_option {
      background-color: white;
      padding: 10px 15px;
      outline: none;
      border: 0;
      color: $text-color;
      font-family: Roboto-Light, sans-serif;
      font-size: 15px;
      border-top: solid 1px $light-background-color;
      cursor: pointer;
      transition: background-color .2s;

      &:hover {
        background-color: $light-background-color;
      }
    }
  }

  .select_inner {
    background-color: $light-background-color;
    padding: 8px;
    position: relative;
    border: 1px solid $light-background-color;
    transition: border-color .2s;

    &.select_error_inner {
      border-color: $error-color;
    }
  }

  .label {
    font-family: Roboto-Medium, sans-serif;
    margin-bottom: 10px;
    display: inline-block;
    color: $text-color;
  }

  .error {
    font-family: Roboto-Light, sans-serif;
    color: $error-color;
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 10px;
  }
  .info {
    font-family: Roboto-LightItalic, sans-serif;
    color: $text-color;
    font-size: 16px;
    margin-top: 6px;
  }
}
