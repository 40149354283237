@import './src/helpers/constants';

.login_wrapper {
  background-color: #fff;
  overflow: hidden;
  height: 100vh;

  .background {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  .registration_form_div {
    width: 300px;
    position: absolute;
    padding: 20px;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .error_div {
      position: absolute;
      width: 100%;
      background-color: $error-color;
      padding: 20px;
      left: 0;
      top: 105%;

      .error_text {
        color: white;
      }
    }
  }

  .logo {
    position: absolute;
    left: 10px;
    width: 200px;
  }

  .login_button {
    margin-top: 10px;
  }

  .input_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .password_input {
    margin-right: 10px;
  }

  .password_eye_button {
    height: 38.5px;
    margin-bottom: 10px;
    padding: 10px 10px;
  }
}

