@import "src/helpers/constants";

.edit_button {
  background-color: $accent-color-light;
  height: fit-content;
  cursor: pointer;
  border: solid 1px $accent-color-light;
  transition: all .2s;

  &:hover {
    border-color: $accent-color;
    background-color: $accent-color-light !important;
  }

  &:active {
    background-color: $accent-color !important;
  }

  &_text {
    font-family: Roboto-Light, sans-serif !important;
    font-size: 18px;
    color: $accent-color-text !important;
  }
}
