@import "src/helpers/constants";

.single_page_header {
  padding: 20px;
  background-color: $light-background-color;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;

  .single_page_left_part {
    display: flex;
    align-items: center;
  }

  .go_back {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

    &_icon {
      width: 24px;
      height: 24px;
    }
  }

  .single_page_title {
    font-family: Roboto-Medium, sans-serif;
    font-size: 24px;
    color: $text-color;
  }
}
