@import "src/helpers/constants";
.single_checklist_wrapper {

  .no_template {
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
  }

  .single_checklist_container {
    padding-left: 20px;
    max-width: 700px;

    .id_row {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .bold_text {
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      margin-right: 10px;
    }
    .simple_text {
      color: $text-color;
      font-family: Roboto-Light, sans-serif;
      margin-right: 10px;
    }

    .template_input {
      margin: 30px 0;
    }

    .template_input_large {
      margin-bottom: 30px;
      min-height: 100px;
      max-height: 300px;
      resize: vertical;
    }

    .template_select {
      width: 100%;
    }
  }

  .tools_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .tools_wrapper {
    width: 140%;

    height: 550px;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;

    .tools_loading {
      margin: auto;
    }

    .no_tools {
      color: $text-color;
      font-family: Roboto-Regular, sans-serif;
      font-size: 16px;
      margin: auto;
    }

    .tools_column {
      width: 48%;
    }

    .tools_item {
      width: 100%;
      margin: 16px 0;

      &:active {
        transform: scale(0.98);
      }
    }
  }

  .controls_wrapper {

    .control_item {
      margin-top: 40px;

      .control_item_title {
        color: $text-color;
        font-family: Roboto-Medium, sans-serif;
        font-size: 22px;
        margin-bottom: 20px;
      }

      .control_item_small_title {
        color: $text-color;
        font-family: Roboto-Medium, sans-serif;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .control_item_inner {
        padding-left: 20px;
      }

      .control_item_input_wrapper_doc {
        margin-bottom: 20px;
      }

      .control_item_input_wrapper {
        margin-bottom: 10px;
      }

      .control_item_input {
        height: 53px;
        resize: none;
      }

      .control_item_row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .buttons_div {
          width: fit-content;
          margin-right: 10px;
        }

        .inputs_div {
          flex: 1;
        }

        .controls_button {
          margin-bottom: 10px;
          padding: 6px 8px;

          .controls_button_icon {
            width: 16px;
            object-fit: contain;
          }
        }
      }
    }
  }

  .buttons_row {
    display: flex;
    margin-top: 50px;
  }
}
