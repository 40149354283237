@import "src/helpers/constants";

.add_drawing_wrapper {
  .new_drawing_title {
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
    margin: 50px 0;
    font-size: 24px;
  }

  .add_drawing_row {
    display: flex;
    align-items: center;

    & img {
      height: 80px;
      object-fit: contain;
    }

    & span {
      margin-left: 40px;
      max-width: 800px;
      color: $text-color;
      font-family: Roboto-Light, sans-serif;
      font-size: 14px;
    }
  }

  .add_drawing_buttons_row {
    margin-top: 60px;
    display: flex;
    align-items: center;
  }

  .add_drawing__no_files {
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
    font-size: 16px;
    width: 200px;
  }

  .add_drawing_input {
    display: none;
  }

  .files_part {
    width: 300px;
  }

  .file_item {
    margin-bottom: 10px;
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: color .2s, opacity .2s;

    &:hover {
      color: $main-color
    }

    &:active {
      opacity: 0.6;
    }

    .file_close {
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }

    .file_item_text {
      max-width: 220px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .create_button {
    display: inline-block;
    margin-top: 40px;
  }

  .only_jpg_pdf_text {
    color: $error-color;
    font-family: Roboto-Regular, sans-serif;
    margin-top: 20px;
  }

}


.delete_drawing_wrapper {
  padding: 20px;

  .delete_drawing_text {
    color: $text-color;
    font-family: Roboto-Regular, sans-serif;
    margin-bottom: 18px;
    max-width: 400px;
  }

  .delete_drawing_info {
    color: $text-color;
    font-family: Roboto-Medium, sans-serif;
    margin-bottom: 18px;
    max-width: 400px;
    text-align: center;
  }

  .delete_drawing_row {
    display: flex;
    justify-content: space-evenly;
  }
}
