@import "src/helpers/constants";

.single_client_wrapper {
  .single_client_input {
    margin-bottom: 20px;
  }

  .buttons_row {
    display: flex;
  }

  .selected_role {
    margin-bottom: 20px;

    .selected_role_title {
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      font-size: 20px;
    }
  }

  .line {
    height: 1px;
    background-color: $label-color;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .simple_error {
    color: $error-color;
    font-family: Roboto-Regular, sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
    text-decoration: underline;
  }
}

.no_object {
  color: $text-color;
  font-family: Roboto-Regular, sans-serif;
  font-size: 30px;
}
